import React, { useState } from 'react';
import styles from './Blog.module.scss';
// import { BsArrowUpRight } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { getAllPosts } from '../../../redux/postsReducer';
import truncateText from '../../../utils/shortText';
import dateToStr from '../../../utils/dateToStr';
import { scrollToElement } from '../../../utils/scrollUtils';
import { Link } from 'react-router-dom';

const Blog = ({blogRef}) => {
  const posts = useSelector(getAllPosts);

  const [visibleBlocks, setVisibleBlocks] = useState(4);

  const showMore = () => {
    setVisibleBlocks(prevVisibleBlocks => prevVisibleBlocks + 4); // Показать следующие 4 блока
  };

  // const showAll = () => {
  //   setVisibleBlocks(posts.length); // Показать все
  // };

  const show4 = () => {
    setVisibleBlocks(4); // Показать 4
    scrollToElement('BlogSection', -20);
  };

  return (
    <section className={styles.Blog} id={'BlogSection'} ref={blogRef}>
      <div className={styles.titles}>
        <div>
          <h2>Наш блог</h2>
        </div>
        {/* <div>
          <a href='/'>Все новости</a> <BsArrowUpRight />
        </div> */}
      </div>
      <div className={styles.BlogList}>
        {posts.slice(0, visibleBlocks).map(post => (
          <div className={styles.BlogListBlock} key={post.id}>
            <div className={styles.BlogListBlockImage}>
              <Link to={`/Blog/${post.id}`}>
                <img src={post.image} alt={'ImageForPost' + post.id}></img>
              </Link>
            </div>
            <div className={styles.BlogListBlockContent}>
              <p><small>Дата публикации: {dateToStr(post.publishedDate)}</small></p> <br />
              <span className={styles.mainContent}>
                <p><b>{post.title}</b></p> <br />
                <p>{truncateText(post.content)}</p> <br />
              </span>
              <Link to={`/Blog/${post.id}`}><small>Читать больше</small></Link>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.showMore}>
        {visibleBlocks < posts.length ? (
          visibleBlocks <= 4 ? (
            <button className={styles.showMoreBtn} onClick={showMore}><u>Показать больше</u></button>
          ) : (
            <>
              <button className={styles.showMoreBtn} onClick={showMore}><u>Показать больше</u></button>
              <button className={styles.showMoreBtn} onClick={show4}><u>Свернуть</u></button>
            </>
          )
        ) : (
          <button className={styles.showMoreBtn} onClick={show4}><u>Свернуть</u></button>
        )}
      </div>
    </section>
  )
}

export default Blog