import React, { useState } from 'react';
import styles from './ContactForm.module.scss';
import PropTypes from 'prop-types'

const ContactForm = ({titleContent}) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [formData, setFormData] = useState({
    Name: '',
    PhoneNumber: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
  
    try {
      const response = await fetch('https://hiljuris.com/api/notify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        setIsSuccess(true);
        setMessage('Заявка успешно отправлена.');
      } else {
        setIsSuccess(false);
        const responseData = await response.json();
        const responseCode = responseData.responseCode;

        switch (responseCode) {
          case 'PhoneNumberWrongFormat':
            setMessage('Неверный формат номера.');
            break;
          case 'RequestAlreadyCreated':
            setMessage('Заявка уже существует.');
            break;
          case 'NameWrongFormat':
            setMessage('Имя не может быть пустым или длинне 50 символов.');
            break;
          case 'CommentWrongFormat':
            setMessage('Комментарии не может быть длинее 600 символов.');
            break;
          default:
            setMessage('Что то пошло не так, попробуйте позже');
            break;
        }
      }
    } catch (error) {
      setMessage('Что то пошло не так, попробуйте позже');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.contactFrom}>
      <div className={styles.textBlock}>
        <span className={styles.title}>
          {titleContent}
        </span>
        <span className={styles.content}>
          Закажите обратный звонок - мы вам перезвоним
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.inputsBlock}>
          <input required onChange={handleChange} value={formData.Name} name="Name" type='text' placeholder='Имя'></input>
          <input required onChange={handleChange} value={formData.PhoneNumber} name="PhoneNumber" type='tel' placeholder='+48'></input>
        </div>
        <button><p>оставить заявку</p></button>
      </form>
      {message && (
        <p style={{ color: isSuccess ? 'green' : 'red' }}>
          {message}
        </p>
      )}
    </div>
  )
}

ContactForm.propTypes = {
  titleContent: PropTypes.string
}

export default ContactForm; 