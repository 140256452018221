import React from 'react';
import styles from './AboutUs.module.scss';
import { useSelector } from 'react-redux';
import { getAllAdvantages } from '../../../redux/advantagesReducer';
import clsx from 'clsx';

const AboutUs = ({aboutUsRef}) => {
  const advantages = useSelector(getAllAdvantages);

  return (
    <section id={'aboutUsSection'} ref={aboutUsRef} className={styles.aboutUs}>
      <div className={styles.aboutCompany}>
        <h2>
          О компании
        </h2>
        <span className={styles.longText}>
          HilJuris - юридическая компания, специализирующейся на легализации в Польше. Мы предоставляем полный спектр юридических услуг в Польше для иностранных граждан и предпринимателей.
        </span>
        <span className={styles.shortText}>
          <p>
            Мы предлагаем нашим клиентам индивидуальный подход и персонализированные решения для каждого случая. 
            Наша команда готова оказать полную поддержку и помощь в решении любых юридических вопросов в Польше.
          </p>
        </span>
        <span className={styles.shortText}>
          <p>
            Наша команда состоит из высококвалифицированных юристов, имеющих глубокие знания в области польского законодательства. Мы гарантируем высокое качество услуг и успешное завершение процессов легализации благодаря опыту и знаниям наших специалистов.
          </p>
        </span>
        {/* <span>
          Свяжитесь с нами, если вам нужна помощь в легализации в Польше. Мы с радостью ответим на любые вопросы и поможем вам достичь успеха в ваших юридических делах.
        </span> */}
      </div>
      <div className={styles.imageBlock}>
        
      </div>
      <div className={styles.advantages}>
        <div className={styles.advantagesTitle}><h2>Наши преимущества</h2></div>
        <div className={styles.advantagesBlockWrapper}>
          <div className={styles.advantagesBlockWrapperCol1}>
            {advantages.slice(0, 4).map(advantage => (
              <div className={styles.advantagesBlock} key={advantage.id}>
                <div className={clsx(styles.advantagesBlockNumber, advantage.id === '1' && styles.advantagesBlockNumber1)}>{advantage.id}</div>
                <span className={styles.advantagesBlockDescription}>{advantage.description}</span>
              </div>
            ))}
          </div>
          <div className={styles.advantagesBlockWrapperCol2}>
            {advantages.slice(4).map(advantage => (
              <div className={styles.advantagesBlock} key={advantage.id}>
                <div className={clsx(styles.advantagesBlockNumber, advantage.id === '1' && styles.advantagesBlockNumber1)}>{advantage.id}</div>
                <span className={styles.advantagesBlockDescription}>{advantage.description}</span>
              </div>
            ))}
          </div>
          {/* {advantages.map(advantage => (
            <div className={styles.advantagesBlock} key={advantage.id}>
              <div className={clsx(styles.advantagesBlockNumber, advantage.id === '1' && styles.advantagesBlockNumber1)}>{advantage.id}</div>
              <span className={styles.advantagesBlockDescription}>{advantage.description}</span>
            </div>
          ))} */}
        </div>
      </div>
    </section>
  )
}

export default AboutUs